/* .nav-bar{
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    justify-content: grid-end;
}
.nav-menu ul{
    display: flex;
    gap:40px;
    justify-content: end;
    
}
.nav-menu ul li{
    list-style-type: none;
}
.nav-logo img{
    width:220px;
}
.m-icon{
    font-size: 30px;
    cursor: pointer;
}
.mobile-menu{
    display: none;
} */

/* ==== Media for 767px=== */

/* @media(max-width:767px){

    .nav-menu ul{
        display: flex;
        flex-direction: column;
        gap:30px;
    
    }
    .mobile-menu{
        display: block;
    }
}
     */


     /* ======= Check Navbar ======== */

     .container {
        max-width: 90%;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 100%;
      }
      
      .navbar {
        height: 80px;
        /* background-color: #fef7e5; */
        position: relative;
      }
      
      .logo {
        width: 180px;
      }
      
      .menu-icon {
        display: none;
      }
      .menu-icon svg{
        font-size: 30px;
      }
      
      .nav-elements {
      }
      
      .nav-elements ul {
        display: flex;
        justify-content: space-between;
        list-style-type: none;
      }
      
      .nav-elements ul li:not(:last-child) {
        margin-right: 60px;
      }
      
      .nav-elements ul a {
        font-size: 16px;
        font-weight: 400;
        color: #2f234f;
        text-decoration: none;
      }
      
      .nav-elements ul a.active {
        color: #574c4c;
        font-weight: 500;
        position: relative;
      }
      
      .nav-elements ul a.active::after {
        content: '';
        position: absolute;
        bottom: -4px;
        left: 0;
        width: 100%;
        height: 2px;
        background-color: #574c4c;
      }
      
      @media (max-width: 768px) {
        .nav-elements ul li:not(:last-child) {
          margin-right: 30px;
        }
      }
      
      @media (max-width: 600px) {
        .menu-icon {
          display: block;
          cursor: pointer;
        }
      
        .nav-elements {
          position: absolute;
          right: 0;
          top: 60px;
          background-color: #fef7e5;
          width: 0px;
          height: calc(100vh - 60px);
          transition: all 0.3s ease-in;
          overflow: hidden;
        }
      
        .nav-elements.active {
          width: 270px;
        }
      
        .nav-elements ul {
          display: flex;
          flex-direction: column;
          padding: 0 3rem;
        }
      
        .nav-elements ul li {
          margin-right: unset;
          margin-top: 22px;
        }
      }