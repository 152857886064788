.seven-accounting-content{
    display: grid;
    grid-template-columns: repeat(2,1fr);
    align-items: center;
    grid-gap: 80px;
    padding: 70px 15px;
}
.section-spacing{
    padding: 30px 0px;
}
.seven-accounting-content h3{
    padding-bottom: 20px;
}


@media(max-width:767px){
    .seven-accounting-content {
        display: grid;
        grid-template-columns: auto;
        grid-gap: 40px;
    }
}