.hero{
    display: grid;
    grid-template-columns: 1.1fr 1fr;
    gap: 50px;
    align-items: center;
}
.hero h1{
    padding:30px 0;
}
.hero p{
    font-size: 22px;
    padding-bottom: 30px;
}
.hero .btn-secondary{
    margin-left:20px;
}


@media(max-width:767px){
    .hero{
        display: grid;
        grid-template-columns: auto;
    }
    .hero h1 {
        padding: 20px 0;
    }
    .hero p {
        font-size: 18px;
        padding-bottom: 30px;
    }
    
}