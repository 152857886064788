.experience{
    background-color: #e0f1ee;
}
.experienece-data{
    display: grid;
    grid-template-columns: repeat(3,1fr);
    justify-content: center;
    text-align: center;
}
.eicon{
    color: var(--primary-color);
}
.experienece-inner-data span{
    display: block;
}
.experience .section-spacing{
padding: 70px;
}
.e-subtitle{
    font-weight: 400;
    padding: 10px;
}
.experienece-inner-data:hover .eicon{
    transform: translateY(-3px);
    transition: 0.3s;
}

@media(max-width:767px){
    .experienece-data {
        grid-template-columns: auto;
        grid-gap: 30px;
    }
}