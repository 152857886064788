/* .contact-form-spacing{
    background-color: #e0f1ee;
}
.contact-form-spacing .section-spacing{
    padding: 70px 0;
}
.eccounting-contcat-form{
    display: grid;
    justify-content:center;   
}
form{
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.411);
    background-color: #fff;
    padding: 50px;
    border-radius: 20px;

}
form input{
    width: 400px;
    padding: 10px;
}
.eccounting-contcat-form label{
    display: block;
    padding: 20px 0px 5px 0px;
}
button{
    display: block;
    width: 100%;
    margin-top:20px;
    padding: 10px 0px;
    cursor: pointer;
} */


.user-rigiter{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
}
.user-rigiter input{
    padding: 1rem;
    border-radius: 0.5rem;
    border: none;
    outline: none;
    background-color: var(--white-color);
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.555);
    width: 60%;
    margin:0.5rem 0;
}
.user-rigiter button{
    background-color: var(--primary-color);
    padding: 1rem;
    border-radius: 0.5rem;
    color: #fff;
    cursor: pointer;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.555);
    width: 60%;
    margin:0.5rem 0;
    border: none;
}

@media(max-width:767px){
    .user-rigiter input,.user-rigiter button{
        width:90%;
    }
}