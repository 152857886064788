.project-gallery-content{
    display: flex;
    justify-content: center;
    gap:30px;
}
.filter-project{
    display: grid;
    grid-template-columns: repeat(3,1fr);
    grid-gap:20px;
    justify-content: center;
}
.filter-project-content{
    display: block;
    text-align: center;
}
.galler-img img{
    width:330px;
    border-radius: 10px;
    cursor: pointer;
}

.galler-img img:hover{
    opacity: 0.6;
    transition: 0.2s;
}

@media(max-width:767px){

    .filter-project {
        display: grid;
        grid-template-columns: repeat(2,1fr);
        grid-gap: 10px;
    }
    .project-gallery-content {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 15px;
    }
}