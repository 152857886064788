.business-help{
    display: grid;
    grid-template-columns: repeat(3,1fr);
    grid-gap: 50px;
    padding: 60px 0;
}
.section-spacing h2{
    text-align: center;
}

.business-content{
    display: flex;
    align-items: center;
}
.business-content h5{
    color: #000;
    transition: 0.5s;

}
.business-content h5:hover{
    color: var(--secondary-color);    ;
}

.business-inner-content{
    margin-left:25px;

}
.b-icon{
    background-color: #5bb7a630;
    color: var(--secondary-color);
    font-size: 60px;
    padding:12px;
    border-radius: 5px;
    transition: 0.5s;
}
.business-content:hover .b-icon{
    transform: translateY(-3px);
}

@media(max-width:767px){
    .business-help{
        display: grid;
        grid-template-columns: repeat(2,1fr);
        grid-template-columns: auto;
    justify-content: center;
    }
    .business-help {
        grid-gap: 20px;
        padding: 20px 0;
    }
}