*{
    padding: 0px;
    margin: 0px;
    box-sizing: border-box;
    font-size: 17px;
    font-family: "poppins";
}

/* ///////////  Global Colors  /////////// */

:root{
    --primary-color:#ed6f2d;
    --primary-hover-color:#cf5819;
    --secondary-color:#5bb7a6;
    --secondary-hover-color:#3C9686;
}
/* ///////////  Global Heading  /////////// */

:root{
    --primary-font:"poppins";
    --primary-font-weight:400;
}


/* =========== Heading Style ========== */


h1{
    font-size: 50px;
    line-height: 65px;
    font-weight: var(--primary-font-weight);
}
h2{
    font-size: 40px;
    line-height: 50px;
    font-weight: var(--primary-font-weight);
}
h3{
    font-size: 36px;
    font-weight: var(--primary-font-weight);
}
h3{
    font-size: 30px;
    font-weight: var(--primary-font-weight);
}
h4{
    font-size: 26px;
    font-weight: var(--primary-font-weight);
    color: var(--primary-color);
}
h5{
    font-size: 22px;
    font-weight: var(--primary-font-weight);
    color: var(--primary-color);

}

/* =========== Paragraph Style ========== */

p{
    font-size: 18px;
    line-height: 1.3em;
    font-weight: var(--primary-font-weight);
}
a{
    text-decoration: none;
    display: inline-block;
    
}
/* =========== Global Section Spacing ========== */

.section-spacing{
    max-width: 90%;
    padding: 70px 0px;
    margin: auto;
}

img{
    max-width: 100%;
    height: auto;
}




/* =========== Global Buttons Style ========== */
.btn-primary{
    background-color: var(--primary-color);
    padding: 12px 32px;
    border-radius: 5px;
    color: #fff;
    border-bottom: 2px solid var(--primary-color);

}
.btn-primary:hover{

    background-color: var(--primary-hover-color);
}

.btn-secondary{
    background-color: transparent;
    padding: 12px 10px;
    border-bottom: 2px solid var(--primary-color);
    display: inline-flex;
    align-items: center;
    transition: 0.2s;
}
.btn-secondary:hover{
    color: var(--primary-color);
}


@media(max-width:786px){
    
    html{
        font-size: 50%;
    }
}

@media(max-width:767px){
    .section-spacing {
        padding: 20px 0px !important;
    }
    h1{
        font-size: 30px;
        line-height: 40px;
    }
    h2{
        font-size: 26px;
        line-height: 36px;
        font-weight: 600;
    }
    h3{
        font-size: 22px;
        font-weight: 500;

    }
    h3{
        font-size: 20px;
        font-weight: 600;

    }
    h4{
        font-size: 18px;
    }
    h5{
        font-size: 16px;
        font-weight: 500;
    
    }
    p {
        font-size: 16px;
        line-height: 1.3em;
    }
    .btn-primary {
        padding: 12px 25px;
        
    }
}

